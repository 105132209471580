<template>
  <div id="payResult" v-title :data-title="textTitle" :style="{backgroundImage:`url(${bgImg})`}">
    <div class="content" style="padding: 30px;background: #fff;">
      <!--状态-->
      <div class="pay-state">
        <div style="text-align: center;">
          <img src="@/assets/img/icon.png" alt="" />
          <div class="pay-state-title">提交成功</div>
        </div>
        <p class="subTheme_color" style="margin-bottom:0.6rem;text-align: center;">我们会24小时内为您受理</p>
        <div style="position: relative;margin-bottom:.3rem;">
          <p class="pay-state-txt-title">
            注意事项：
          </p>
          <span style="display: inline-block;width: 1.4rem;background: #e5f3f7;height: .16rem;"></span>
        </div>
        <div v-if="showDefaultTip" style="text-align: left;font-size:.28rem;">
          <p  v-if="this.vueType == 'kd' ">
            <span class="subTheme_color tip-text">1、校园宽带业务通过线上开通成功即可使用，无需上门安装。<br/></span>
            <span class="subTheme_color tip-text">2、后续收到运营商的评价短信请回复1（10分满意），感谢您的支持！<br/></span>
            <span class="subTheme_color tip-text">3、如有问题，可咨询微信公众号“大学掌”客服。</span>
          </p>
          <p v-if="this.vueType == 'yx' ">
            <span class="subTheme_color tip-text">	1、校园营销业务通过线上开通成功即可使用，无需上门安装。<br/></span>
            <span class="subTheme_color tip-text">2、后续收到运营商的评价短信请回复1（10分满意），感谢您的支持！<br/></span>
            <span class="subTheme_color tip-text">3、如有问题，可咨询微信公众号“大学掌”客服。</span>
          </p>
          <p v-if="this.vueType == 'kk' ">
            <span class="subTheme_color tip-text">1、校园开卡业务通过线上开通成功即可使用，无需上门安装。<br/></span>
            <span class="subTheme_color tip-text">2、后续收到运营商的评价短信请回复1（10分满意），感谢您的支持！<br/></span>
            <span class="subTheme_color tip-text">3、如有问题，可咨询微信公众号“大学掌”客服。</span>
          </p>

        </div>
        <!--温馨提示-->
        <div v-if="!showDefaultTip" class="resultWeb" style="font-size:.28rem;border-bottom:1px dashed #ebedf0;padding-bottom:.1rem;" v-html="resultWeb"></div>
      </div>
      <!--楼长二维码-->
      <div class="housemaster" style="margin-top:.5rem;" v-if="qunServer">
        <div style="position: relative;margin-bottom:.3rem;">
          <p class="pay-state-txt-title">专属客服:</p>
          <span style="display: inline-block;width: 1.4rem;background: #e5f3f7;height: .16rem;"></span>
        </div>
        <div class="flex">
          <img :src="imgUrl" style="max-width: 2rem;margin-right:10px;max-height: 2rem;min-width: 2rem;min-height: 2rem;" alt="" />
          <div style="font-size:.28rem;" v-if="schoolRoom">
            <p>添加客服微信 发送学校+姓名+手机号，订单急速办理</p>
          </div>
        </div>
      </div>
    </div>
	<img src='~@/assets/img/result-bg1.png' style="margin-top: -6%;position: relative;width: 97%;margin-left: 1%;"/>
  <div style="height: 1.3rem"></div>
  </div>
</template>

<script>
export default {
  name: "PagePayResult",
  components: {},
  props: {
    // 接口前缀地址
    url: String,
    // 业务类型 (kd 宽带 kk 开卡 yx 营销) // TODO 每个模块有多不同的可以根据属性值判断
    businessType: String
  },
  data() {
    return {
      bgImg: require("@/assets/img/home-bg.png"),
      contentBg: require("@/assets/img/result-bg.png"),
      textTitle:'',
      enterType: this.$route.query.enterType || 0,
      orderCode: this.$route.query.orderCode || 0,
      resultWeb: "",
      showDefaultTip: false, // 是否显示默认的提示信息
      imgUrl: "", // 二维码
      schoolRoom: "",
		qunServer:true,
      vueType:this.businessType
    };
  },
  mounted() {},
  created() {
    this.getVueType();
    this.getResultWeb();
  },
  methods: {
    getVueType(){
      const type = this.vueType;
      if(type == 'kk'){
        this.bgImg = require("@/assets/img/opencard.png");
        this.textTitle = '开卡业务'
      }else if(type == 'yx'){
        this.bgImg = require("@/assets/img/yx.png");
        this.textTitle = '营销业务'
      }else if(type == 'kd'){
        this.bgImg = require("@/assets/img/home-bg.png");
        this.textTitle = '校园网业务'
      }
    },
    /* 获取结果页信息 */
    getResultWeb() {
		let url = this.url + "result-page";
		this.axios.get(url, {
          params: {
            enterType: this.enterType,
            orderCode: this.orderCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.resultWeb = res.data.data;
            this.showDefaultTip = !this.resultWeb;
            if( !res.data.imgurl){
              this.qunServer = false
            }else{
              this.imgUrl = window.serverConfig.baseUrl +"file/"+ res.data.imgurl;
            }
            this.schoolRoom = res.data.imgmsg;
          } else {
            this.showDefaultTip = true;
            this.qunServer = false
          }
        })
        .catch(() => {
          this.showDefaultTip = true;
        });
    },
  },
};
</script>
<style scoped  lang="scss">
</style>
<style>
.resultWeb p{margin-bottom:.2rem;}
pre {
  white-space: break-spaces;
}
</style>
